let Navigation = {

    Open: event => {

        document.querySelector("body").addEventListener("click", Navigation.Close);

        document.querySelector("html").dataset.navigationOpen = "";

        if (event) {

            event.preventDefault();
            event.stopPropagation();

            return false;

        }

    },

    Close: event => {

        document.querySelector("body").removeEventListener("click", Navigation.Close);

        delete document.querySelector("html").dataset.navigationOpen;

        if (event) {

            event.preventDefault();
            event.stopPropagation();

            return false;

        }

    },

    IsOpened: () => document.querySelector("html").dataset.navigationOpen !== undefined,

    Toggle: event => Navigation[Navigation.IsOpened() ? "Close" : "Open"](event)

}

module.exports = Navigation;