module.exports = class {

    constructor(element, player) {

        this.element = element;
        this.video = this.element.querySelector("video");

        if (!this.video) {

            this.div = this.element.querySelector(".player");
            player.loadVideoById(this.div.getAttribute("data-video"));
            player.stopVideo();

        }

        element.addEventListener("click", () => {

            if (!this.element.hasAttribute("data-hide")) {

                this.hide(player);

            }

        });

    }

    hide(player) {

        if (player) {

            player.playVideo();

        } else {

            this.video.play();

        }
        
        this.element.dataset.hide = 1;

    }

}