// Dependencies
require("nodelist-foreach-polyfill");
require("intersection-observer");
require("particles.js");

// // Components
const Navigation = require("./components/Navigation");
const Video = require("./components/Video");
const YouTubePlayer = require('youtube-player');
require("./components/Conceal");

// Configuration
IntersectionObserver.POLL_INTERVAL = 100;

window.onpageshow = function (event) {
    if (event.persisted) {
        window.location.reload()
    }
};

// // Initialize
window.addEventListener("DOMContentLoaded", () => {

    // ---------------
    // Scroll to first marquee on splash's arrow click (home page)
    if (document.querySelector(".splash-more")) {

        document.querySelector(".splash-more").addEventListener("click", () => {

            document.querySelectorAll(".marquee-container")[0].scrollIntoView({
                behavior: "smooth"
            });

        });

    }

    // ---------------
    // Intersection observer / Viewport
    let options = {
        rootMargin: "20px",
        threshold: 0.10
    };

    let observer = new IntersectionObserver(entries => {

        entries.forEach(entry => {

            if (entry.intersectionRatio >= options.threshold) {

                entry.target.dataset.viewport = 1;
                entry.target.dataset.direction = 0;

            } else if (!entry.isIntersecting && entry.rootBounds) {

                entry.target.dataset.direction = entry.boundingClientRect.y < entry.rootBounds.y ? -1 : 1;

                if (entry.target.dataset.restrictDirection) {

                    if (entry.target.dataset.restrictDirection != entry.target.dataset.direction) return;

                }

                    entry.target.dataset.viewport = 0;

                }

            });

        }, options);

        document.querySelectorAll("[data-viewport]").forEach(element => observer.observe(element));


        // ---------------
        // Navigation
        document.querySelector(".header-global button").addEventListener("click", Navigation.Toggle);


        // ---------------
        // Lazyload images
        document.querySelectorAll("img[data-src]").forEach(element => {

            element.setAttribute("src", element.dataset.src);
            element.setAttribute("srcset", element.dataset.srcset);

            delete element.dataset.src;
            delete element.dataset.srcset;

        });


        // ---------------
        // Anchors
        document.querySelectorAll('a[href^="/"]').forEach(element => {

            element.addEventListener("click", event => {

                if (event.metaKey) return true;

                let href = element.getAttribute("href"),
                    root = document.querySelector("html");

                root.dataset.state = "loading";

                if (navigator.vibrate) {

                    navigator.vibrate(30);

                }

                root.addEventListener("transitionend", event => {

                    if (event.pseudoElement === "::after") {

                        window.location.href = href;

                    }

                });

                // If the listener fails...
                setTimeout(() => {

                    window.location.href = href;

                }, 750);

                event.preventDefault();

                return false;

            });

        });

        // ---------------
        // Sibling Anchors
        // Dependency for Element.getSiblings
        if (!('getSiblings' in Element.prototype)) {

            Element.prototype.getSiblings = function (selector) {

                var siblings = [],
                    sibling = this.parentElement.firstElementChild;

                while (sibling) {

                    if (sibling !== this && (!selector || (selector && !sibling.matches(selector)))) {

                        siblings.push(sibling);

                    }

                    sibling = sibling.nextElementSibling;

                }

                return siblings;

            };
        }

        document.querySelectorAll("body > header nav a").forEach(element => {

            let siblings = element.getSiblings();

            element.addEventListener("mouseenter", () => {

                element.dataset.hover = 1;

                siblings.forEach(sibling => sibling.dataset.hover = 0);

            });

            element.addEventListener("mouseleave", () => {

                delete element.dataset.hover;

                siblings.forEach(sibling => delete sibling.dataset.hover);

            })

        });


        // ---------------
        // Particles
        document.querySelectorAll("[data-particles]").forEach(element => {

            let id = element.getAttribute("id");

            if (!id) {

                id = "particles-" + (Math.random() * 100000).toFixed();

                element.setAttribute("id", id);

            }

            particlesJS.load(id, "/assets/" + element.dataset.particles);

        });

        // -----------
        // video overlay
        document.querySelectorAll(".videoWrapper").forEach(element => {

            var player;

            if (element.querySelector(".player")){
                var player = new YouTubePlayer(element.querySelector(".player"));
            }

            new Video(element, player);

        });

        // --------------
        // Page ready
        // wait for the animation to finish before deleting it
        function whichAnimationEvent() {
            var t,
                el = document.createElement("div");

            var animations = {
                "animation": "animationend",
                "OAnimation": "oAnimationEnd",
                "MozAnimation": "animationend",
                "WebkitAnimation": "webkitAnimationEnd"
            }

            for (t in animations) {
                if (el.style[t] !== undefined) {
                    return animations[t];
                }
            }
        }

        var animationEvent = whichAnimationEvent();

        document.querySelector(".site-loading svg").addEventListener(animationEvent,
            function (event) {
                document.querySelector("html").setAttribute("data-state", "ready");
            }
        , false);

        setTimeout(function () {
            document.querySelector("html").setAttribute("data-state", "ready");
        }, 8000);

});