const threshold = 200;

module.exports = new class {

    constructor() {

        this.scroll = window.scrollY;
        this.resting = this.scroll;

        this.target = document.querySelector("html");

        window.addEventListener("resize", this.OnResize.bind(this));

        requestAnimationFrame(this.Update.bind(this));

    }

    Update() {

        requestAnimationFrame(this.Update.bind(this));

        let scrollY = window.scrollY,
            delta = scrollY - this.scroll;

        if (delta == 0) return;

        this.scroll = scrollY;

        if (delta < 0 && scrollY <= this.resting) {

            // Reveal
            delete this.target.dataset.concealed;

            this.resting = scrollY;

        } else if (scrollY >= this.resting + threshold) {

            // Conceal
            this.target.dataset.concealed = "";
            
            this.resting = scrollY - threshold;

        }
    }

    OnResize() {

        this.min = 0;
        this.max = document.documentElement.scrollHeight - document.documentElement.clientHeight;

    }
}